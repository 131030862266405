import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { disableScroll, enableScroll } from "./_helpers.js";
const drawSvg = require("./drawSVGPlugin.js");

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(drawSvg);

class Header {
  constructor() {
    this.header = document.querySelector("#header");
    this.navItems = document.querySelectorAll(".header-menu-item");
    this.contact = document.querySelector(".contact-buttons-container");
    this.logo = document.querySelector(".logo");
    this.circles = document.querySelectorAll(".main-circle svg path");
    this.navigationContainer = document.querySelector(".nav-container");
    this.navOverlay = document.querySelector(".navigation-overlay");
    this.hamburger = document.querySelector(".hamburger");
    this.loadTimeline = gsap.timeline();
    this.menuTimeLine = gsap.timeline();
    this.hamburgerTimeLine = gsap.timeline();
    this.isMenuOpen = false;

    this.initListeners();
    // this.pageLoadedTimeLineInit();
    this.isHeaderScrolledListener();
    this.initHamburgetTimeLine();
    this.displayMenuTimeLine();
  }

  // pageLoadedTimeLineInit = () => {
  //   this.loadTimeline.to(this.navItems, { opacity: 1, y: 0, stagger: { amount: 0.7 }, ease: "Power1. easeOut" }, "start");
  //   this.loadTimeline.to(this.contact, { opacity: 1, duration: 0.7, x: 0, ease: "inear.easeNone" }, "start");
  //   this.loadTimeline.to(this.logo, { y: 0, duration: 0.7, opacity: 1, ease: "Power1. easeOut" }, "start");
  //   this.loadTimeline.fromTo(this.circles, { drawSVG: 0 }, { drawSVG: "100%", duration: 3, ease: "Power1. easeOut" }, "start");
  //   this.loadTimeline.play();
  // };

  isHeaderScrolledListener = () => {
    ScrollTrigger.create({
      start: "top -10",
      end: 99999,
      toggleClass: { className: "header--scrolled", targets: this.header },
    });
  };

  initHamburgetTimeLine = () => {
    this.hamburgerTimeLine.to(".hamburger-middle", { x: 100, opacity: 0, duration: 0.2 }, "first_ster");
    this.hamburgerTimeLine.to(".hamburger-first", { y: "10px", duration: 0.2 }, "second_step");
    this.hamburgerTimeLine.to(".hamburger-last", { y: "-10px", duration: 0.2 }, "second_step");
    this.hamburgerTimeLine.to(".hamburger-first", { rotate: "45deg", duration: 0.2 }, "third_step");
    this.hamburgerTimeLine.to(".hamburger-last", { rotate: "-45deg", duration: 0.2 }, "third_step");
    this.hamburgerTimeLine.pause();
  };

  displayMenuTimeLine = () => {
    this.menuTimeLine.to(this.navOverlay, { x: 0, duration: 0.3 }, "start");
    this.menuTimeLine.to(this.navigationContainer, { x: 0, opacity: 100, duration: 0.3, delay: 0.1 }, "start");
    this.menuTimeLine.fromTo(this.navItems, { opacity: 0, y: 15 }, { opacity: 1, y: 0, stagger: { each: 0.1 }, dealy: 0.1 });
    this.menuTimeLine.pause();
  };

  handleMobileMenu = () => {
    if (!this.isMenuOpen) {
      disableScroll();
      this.menuTimeLine.play();
      this.hamburgerTimeLine.play();
      this.isMenuOpen = !this.isMenuOpen;
      gsap.fromTo(".mobile-menu-circles svg path", { drawSVG: "-50%" }, { drawSVG: "100%", duration: 4, dealy: 0.15 });
    } else {
      enableScroll();
      this.menuTimeLine.reverse();
      this.hamburgerTimeLine.reverse();
      this.isMenuOpen = !this.isMenuOpen;
    }
  };

  initListeners = () => {
    this.hamburger.addEventListener("click", this.handleMobileMenu);
  };
}

export default Header;
