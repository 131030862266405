import lazyLoadImages from "./components/lazyLoad";
import Header from "./components/header.js";
import { ClinicSlider, DoctorsSlider, QuoteSlider, CooperationSlider } from "./components/sliders";
import fslightbox from "fslightbox";
import AOS from "aos";
import cookiePopup from "./components/cookie-popup";
import "aos/dist/aos.css";

window.addEventListener("DOMContentLoaded", () => {
  const header = new Header();
  const clinicSliderContainers = [...document.querySelectorAll(".swiper--clinic")];
  const doctorSliderContainers = [...document.querySelectorAll(".swiper--doctors")];
  const quoteSliderContainers = [...document.querySelectorAll(".swiper--quote-slider")];
  const cooperationSliderContainer = [...document.querySelectorAll(".swiper--cooperation")];

  initSlider(clinicSliderContainers, ClinicSlider);
  initSlider(doctorSliderContainers, DoctorsSlider);
  initSlider(quoteSliderContainers, QuoteSlider);
  initSlider(cooperationSliderContainer, CooperationSlider);

  new cookiePopup();

  AOS.init({
    startEvent: "DOMContentLoaded",
    once: true,
  });
});

const initSlider = (containers = [], Class) => {
  if (containers.length < 1) return;

  containers.forEach((container) => {
    new Class(container);
  });
};

lazyLoadImages();
document.body.addEventListener("force_lazyload", (e) => {
  lazyLoadImages();
});
