import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export class QuoteSlider {
  constructor(container) {
    this.container = container;
    this.initSlider();
  }

  initSlider = () => {
    this.swiper = new Swiper(this.container, {
      slidesPerView: 1,
      navigation: {
        nextEl: ".big-slider-button-next",
        prevEl: ".big-slider-button-prev",
      },
    });
  };
}

export class DoctorsSlider {
  constructor(container) {
    this.container = container;
    this.initSlider();
  }

  initSlider = () => {
    this.slider = new Swiper(this.container, {
      slidesPerView: 1,
      spaceBetween: 0,

      breakpoints: {
        580: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1670: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 4,
          spaceBetween: 70,
        },
      },

      navigation: {
        prevEl: document.querySelector(".doctors-next-button"),
        nextEl: document.querySelector(".doctors-prev-button"),
      },
    });
  };
}

export class CooperationSlider {
  constructor() {
    const swiperCooperation = new Swiper(".swiper--cooperation", {
      slidesPerView: 2,
      spaceBetween: 20,
      loop: true,
      lazy: true,
      autoplay: {
        delay: 100,
      },
      breakpoints: {
        580: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1350: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
        1670: {
          slidesPerView: 6,
          spaceBetween: 40,
        },
        1920: {
          slidesPerView: 6,
          spaceBetween: 86,
        },
      },
    });

    swiperCooperation.autoplay.start();
  }
}

export class ClinicSlider {
  constructor(container) {
    this.container = container;

    this.initSlider();
  }

  initSlider = () => {
    this.slider = new Swiper(this.container, {
      slidesPerView: 1,
      spaceBetween: 0,
      grid: {
        rows: 1,
      },

      breakpoints: {
        // when window width is >= 580px
        580: {
          slidesPerView: 1,
          spaceBetween: 0,
          grid: {
            rows: 2,
          },
        },
        // when window width is >= 790px
        790: {
          slidesPerView: 2,
          spaceBetween: 0,
          grid: {
            rows: 2,
          },
        },
        // when window width is >= 1670px
        1350: {
          slidesPerView: 3,
          spaceBetween: 0,
          grid: {
            rows: 2,
          },
        },
        // when window width is >= 1920px
        1670: {
          slidesPerView: 4,
          spaceBetween: 0,
          grid: {
            rows: 2,
          },
        },
      },

      navigation: {
        prevEl: document.querySelector(".clinic-prev-button"),
        nextEl: document.querySelector(".clinic-next-button"),
      },
    });
  };
}
